/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import img from '../../assets/images/layouts/project-46.png'
import line from "../../assets/images/background/line-2.png";
import avt from "../../assets/images/layouts/avt-05.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
Team2.propTypes = {
  data: PropTypes.array,
};

function Team2(props) {
  const { data } = props;
  return (
    <section className="team s2">
      <div className="shape right"></div>
      <img src={line} alt="" className="img-line" />
      {/* helo */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>Team Members</span>
              </h6>
              <h3 className="heading wow" data-splitting>
                Our Amazing Team
                <br />
                Members
              </h3>
            </div>
          </div>

          {data.map((idx) => (
            <div key={idx.id} className="col-xl-3 col-md-6">
              <div className="team-box">
                <div className="image">
                  <Link to="/team">
                    {/* <LazyLoadImage
                      alt="NFTPros"
                      effect="blur"
                      src={idx.img}
                      className="prop"
                    /> */}
                    {/* lk */}
                    <img src={idx.img} alt="NFTPros"  className="prop"/>
                    {/* <img src={img} alt="NFTPros"  className="profilepic" /> */}
                    <LazyLoadImage
                      alt="NFTPros"
                      effect="blur"
                      src={idx.linked}
                      className="profilepic"
                    />
                  </Link>

                  {idx.url !== null ? <ul className="list-social">
                    <li>
                     { idx.url !== null ? <a href={idx.url} target="_blank">
                        <span className="icon-linkedin"></span>
                      </a>: <a href="#"></a>}
                    </li>
                  </ul> : null}
                </div>
                <div className="content">
                  <Link to="" className="h5 name">
                    {idx.name}
                  </Link>
                  <p className="postion">{idx.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team2;
